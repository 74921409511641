import React, { type ReactNode } from 'react'

import { useMask } from '@/store/mask'

export function PrivacyField({
  children,
  className,
  as: Element = 'span',
  mask = '******'
}: {
  children: ReactNode
  className?: string
  as?: keyof JSX.IntrinsicElements
  mask?: string
}) {
  const { isMasked } = useMask()

  return isMasked ? (
    <Element className={className}>{mask}</Element>
  ) : (
    <Element className={className}>{children}</Element>
  )
}

// only for svg
export function SVGPrivacyField({
  children,
  className,
  mask = '******',
  as = 'text',
  ...svgProps
}: {
  children: ReactNode
  className?: string
  mask?: string
  as?: 'text' | 'tspan' | 'textPath'
  [key: string]: any
}) {
  const { isMasked } = useMask()

  return React.createElement(as, { className, ...svgProps }, isMasked ? mask : children)
}
